import React from 'react';
import { CHART_LINK, PANCAKE_EXCHANGE, TOKEN_ADDRESS } from 'utils/constants';
import { useIntl } from 'react-intl';
import amanda from 'images/amanda-cerny.png';
import Title from 'components/Title';
import Root, {
  Spotlight,
  SpotlightImage,
  AboutUsSectionButtonsWrapper,
  BuyFromPancake,
  PooCoinButton,
  TokenAddressSection,
  TokenAddressLabel,
  TokenAddressValue,
} from './Home.style';

const Home = () => {
  const intl = useIntl();

  return (
    <Root className="container" id="home">
      <div className="row">
        <div className="col-sm-12 col-lg-6">
          <SpotlightImage src={amanda} className="img-thumbnail" alt="To The Moon" width="100%" />
        </div>
        <div className="col-sm-12 col-lg-6">
          <Spotlight>
            <Title>AMANDA FAN TOKEN</Title>
            <p>{intl.formatMessage({ id: 'aboutToken' })}</p>
            <TokenAddressSection>
              <TokenAddressLabel>
                {intl.formatMessage({
                  id: 'tokenAddress',
                  defaultMessage: 'Token Address',
                })}
                :
              </TokenAddressLabel>
              <TokenAddressValue className="address">{TOKEN_ADDRESS}</TokenAddressValue>
            </TokenAddressSection>
            <br />
            <AboutUsSectionButtonsWrapper>
              <BuyFromPancake
                href={PANCAKE_EXCHANGE}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({
                  id: 'buyFromPancakeSwap',
                  defaultMessage: 'BUY from PancakeSwap',
                })}
              </BuyFromPancake>
              <PooCoinButton
                href={CHART_LINK}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({
                  id: 'chart',
                  defaultMessage: 'Chart',
                })}
              </PooCoinButton>
            </AboutUsSectionButtonsWrapper>
          </Spotlight>
        </div>
      </div>
    </Root>
  );
};

export default Home;
