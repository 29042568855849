import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const Root = styled.div`
  padding-top: 5rem;

  ${media.lg`
    padding-top: 7rem;
  `}

  /* The actual timeline (the vertical ruler) */
  .timeline {
    position: relative;
    margin: 2rem auto;
  }

  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    border-radius: 3px;
  }

  /* Container around content */
  .timeline-container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }

  /* The circles on the timeline */
  .timeline-container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -12px;
    background-color: white;
    border: 4px solid ${({ theme }) => theme.primary};
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }

  /* Place the container to the left */
  .timeline-left {
    left: 0;
    padding-left: 0;
  }

  /* Place the container to the right */
  .timeline-right {
    left: 50%;
    padding-right: 0;
  }

  /* Add arrows to the left container (pointing right) */
  .timeline-left::before {
    content: ' ';
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid ${({ theme }) => theme.primary};
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent ${({ theme }) => theme.primary};
  }

  /* Add arrows to the right container (pointing left) */
  .timeline-right::before {
    content: ' ';
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid ${({ theme }) => theme.primary};
    border-width: 10px 10px 10px 0;
    border-color: transparent ${({ theme }) => theme.primary} transparent transparent;
  }

  /* Fix the circle for containers on the right side */
  .timeline-right::after {
    left: -12px;
  }

  /* The actual content */
  .timeline-content {
    padding: 20px 30px;
    background-color: ${({ theme }) => theme.primary};
    position: relative;
    border-radius: 6px;
  }

  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .timeline::after {
      left: 10px;

      ${media.md`
        left: 30px;
      `}
    }

    /* Full-width containers */
    .timeline-container {
      width: 100%;
      padding-left: 40px;
      padding-right: 0;

      ${media.md`
        padding-left: 70px;
        padding-right: 25px;
      `}
    }

    /* Make sure that all arrows are pointing leftwards */
    .timeline-container::before {
      left: 30px;
      border: medium solid ${({ theme }) => theme.primary};
      border-width: 10px 10px 10px 0;
      border-color: transparent ${({ theme }) => theme.primary} transparent transparent;

      ${media.md`
        left: 60px;
      `}
    }

    /* Make sure all circles are at the same spot */
    .timeline-left::after,
    .timeline-right::after {
      left: -3px;

      ${media.md`
        left: 15px;
      `}
    }

    /* Make all right containers behave like the left ones */
    .timeline-right {
      left: 0%;
    }
  }
`;

export default Root;
