import React from 'react';
import Root, { NavItemFilled } from './NavBar.style';
import { COOKIE_KEYS, AVAILABLE_LANGUAGES, TOKEN_ADDRESS, CHART_LINK } from 'utils/constants';
import { useIntl } from 'react-intl';
import cookies from 'js-cookie';

const NavBar = ({ language, setLanguage }) => {
  const intl = useIntl();

  const handleOnLanguageChange = targetLanguage => () => {
    cookies.set(COOKIE_KEYS.LANGUAGE, targetLanguage, { expires: 365 });
    setLanguage(targetLanguage);
  };

  return (
    <Root className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
      <div className="container">
        <a className="navbar-brand" href="#home">
          AMANDA CERNY
          <span style={{ opacity: 0.7, fontSize: '0.8rem', paddingLeft: '0.5rem' }}>Fan Token</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-content"
          aria-controls="navbar-content"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbar-content">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#home">
                {intl.formatMessage({
                  id: 'navbar.home',
                  defaultMessage: 'Home',
                })}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#roadmap">
                {intl.formatMessage({
                  id: 'navbar.roadmap',
                  defaultMessage: 'Roadmap',
                })}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#tokenomics">
                {intl.formatMessage({
                  id: 'navbar.tokenomics',
                  defaultMessage: 'Tokenomics',
                })}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" target="_blank" href={CHART_LINK} rel="noreferrer">
                {intl.formatMessage({
                  id: 'chart',
                  defaultMessage: 'Chart',
                })}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href={`https://bscscan.com/address/${TOKEN_ADDRESS}`}
                rel="noreferrer"
              >
                {intl.formatMessage({
                  id: 'navbar.contract',
                  defaultMessage: 'Contract',
                })}
              </a>
            </li>

            <li className="nav-item dropdown">
              <NavItemFilled
                className="btn btn-primary dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {AVAILABLE_LANGUAGES[language]}
              </NavItemFilled>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {Object.keys(AVAILABLE_LANGUAGES).map(locale => (
                  <li>
                    <span className="dropdown-item" onClick={handleOnLanguageChange(locale)}>
                      {AVAILABLE_LANGUAGES[locale]}
                    </span>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </Root>
  );
};

export default NavBar;
