import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import i18n from 'i18n';
import { ThemeProvider } from 'styled-components';
import getClientLanguage from 'utils/getClientLanguage';
import NavBar from 'components/NavBar';
import Home from 'components/Home';
import Tokenomics from 'components/Tokenomics';
import Roadmap from 'components/Roadmap';
import Footer from 'components/Footer';
import theme from 'theme';

const clientLanguage = getClientLanguage();

function App() {
  const [language, setLanguage] = useState(clientLanguage);

  return (
    <IntlProvider locale={language} messages={i18n[language]}>
      <ThemeProvider theme={theme}>
        <NavBar language={language} setLanguage={setLanguage} />
        <Home language={language} />
        <Tokenomics language={language} />
        <Roadmap language={language} />
        <Footer language={language} />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
