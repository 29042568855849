import React, { useMemo } from 'react';
import Root from './Tokenomics.style';
import Title from 'components/Title';
import { useIntl } from 'react-intl';

const Tokenomics = () => {
  const intl = useIntl();
  const data = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'tokenomics.clause_1.title' }),
        description: intl.formatMessage({ id: 'tokenomics.clause_1.description' }),
      },
      {
        title: intl.formatMessage({ id: 'tokenomics.clause_2.title' }),
        description: intl.formatMessage({ id: 'tokenomics.clause_2.description' }),
      },
      {
        title: intl.formatMessage({ id: 'tokenomics.clause_3.title' }),
        description: intl.formatMessage({ id: 'tokenomics.clause_3.description' }),
      },
    ],
    [intl]
  );

  return (
    <Root id="tokenomics" className="container">
      <Title>TOKENOMICS</Title>
      <div className="row">
        {data.map(({ title, description }) => (
          <div className="col-sm-6">
            <strong>{title}</strong>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </Root>
  );
};

export default Tokenomics;
