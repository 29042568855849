import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const Root = styled.div`
  padding-top: 5rem;

  ${media.lg`
    padding-top: 7rem;
  `}

  strong {
    font-size: 1.5rem;

    ${media.lg`
      font-size: 2rem;
    `}
  }

  p {
    font-size: 1rem;
    opacity: 0.6;
  }
`;

export default Root;
