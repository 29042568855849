import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const NavItemFilled = styled.a`
  background: #fff;
  color: #e5007d;
`;

const Root = styled.nav`
  ${media.lg`
    .dropdown {
      margin-left: 0.5rem;
    }
  `}

  .dropdown-item {
    cursor: pointer;
  }
`;

export default Root;
