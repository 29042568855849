import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const SpotlightImage = styled.img`
  background: #fff;
  display: table;
  margin: 0 auto 1rem auto;
`;

export const AboutUsSectionButtonsWrapper = styled.div`
  margin: 1rem 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.lg`
    margin: 4rem 0 0 0;
    flex-direction: row;
  `}
`;

export const BuyFromPancake = styled.a`
  display: inline;
  flex-grow: 0;
  flex-shrink: 1;
  background: #fff;
  color: #e5007d;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 500;
`;

export const PooCoinButton = styled.a`
  display: inline;
  margin: 1rem 0 0 0;
  flex-grow: 0;
  flex-shrink: 1;
  color: #fff;
  background: #e5007d;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 500;

  ${media.lg`
    margin: 0 0 0 1rem;
  `}
`;

export const Spotlight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TokenAddressLabel = styled.span`
  margin-right: 10px;
  display: inline-block;
`;

export const TokenAddressValue = styled.span`
  background-color: #e5007d;
  border: solid 1px #e5007d;
  border-radius: 5px;
  line-height: 1.5;
  padding: 6px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TokenAddressSection = styled.div`
  display: flex;
  align-self: center;
  line-height: 40px;
  flex-direction: column;
  max-width: 100%;

  .btn-pre-sale {
    color: #fff;
    display: flex;
    justify-self: center;
    flex-grow: 0;

    input {
      background: transparent;
      color: #fff;
      border: 0;
    }
  }

  ${media.md`
    flex-direction: row;
  `}
`;

const Root = styled.div`
  margin-top: 4.5rem;

  h2 {
    margin-top: 0;
  }
`;

export default Root;
