export const COOKIE_KEYS = {
  LANGUAGE: `_amanda.language`,
};

export const TOKEN_ADDRESS = '0xcc42ef8f7c4f5ec9249a886ab2fee525012b26ae';
export const PANCAKE_EXCHANGE =
  // eslint-disable-next-line max-len
  'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xcc42ef8f7c4f5ec9249a886ab2fee525012b26ae';
export const CHART_LINK = 'https://poocoin.app/tokens/0xcc42ef8f7c4f5ec9249a886ab2fee525012b26ae';
export const TELEGRAM_ADDRESS = 'https://t.me/amandacernyfantoken';
export const TWITTER_ADDRESS = 'https://twitter.com/AmandaFanToken';

export const AVAILABLE_LANGUAGES = {
  en: 'English',
  tr: 'Türkçe',
};
