import React from 'react';
import Title from 'components/Title';
import { TELEGRAM_ADDRESS, TWITTER_ADDRESS } from 'utils/constants';
import Root, { LinksContainer, SocialLinks, PoweredByLink } from './Footer.style';
import { useIntl } from 'react-intl';
import wecareLogo from 'images/wecare-logo.svg';

const Footer = () => {
  const intl = useIntl();

  return (
    <Root>
      <SocialLinks>
        <Title>
          {intl.formatMessage({
            id: 'joinUs',
            defaultMessage: 'Join Us',
          })}
        </Title>
        <LinksContainer>
          <a href={TWITTER_ADDRESS} target="_blank" rel="noreferrer">
            Twitter
          </a>
          <a href={TELEGRAM_ADDRESS} target="_blank" rel="noreferrer">
            Telegram
          </a>
        </LinksContainer>
      </SocialLinks>
      <PoweredByLink href="https://wecare.charity" target="_blank">
        Powered by <img src={wecareLogo} alt="Wecare.charity" />
      </PoweredByLink>
    </Root>
  );
};

export default Footer;
