import React, { useMemo } from 'react';
import Root from './Roadmap.style';
import Title from 'components/Title';
import { useIntl } from 'react-intl';

const Roadmap = () => {
  const intl = useIntl();
  const data = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'roadmap.clause_1.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_1.description' }),
      },
      {
        title: intl.formatMessage({ id: 'roadmap.clause_2.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_2.description' }),
      },
      {
        title: intl.formatMessage({ id: 'roadmap.clause_3.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_3.description' }),
      },
      {
        title: intl.formatMessage({ id: 'roadmap.clause_4.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_4.description' }),
      },
      {
        title: intl.formatMessage({ id: 'roadmap.clause_5.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_5.description' }),
      },
      {
        title: intl.formatMessage({ id: 'roadmap.clause_6.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_6.description' }),
      },
      {
        title: intl.formatMessage({ id: 'roadmap.clause_7.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_7.description' }),
      },
      {
        title: intl.formatMessage({ id: 'roadmap.clause_8.title' }),
        description: intl.formatMessage({ id: 'roadmap.clause_8.description' }),
      },
      {
        description: intl.formatMessage({ id: 'roadmap.clause_9.description' }),
      },
    ],
    [intl]
  );

  return (
    <Root id="roadmap" className="container">
      <Title>
        {intl.formatMessage({
          id: 'title.roadmap',
          defaultMessage: 'ROADMAP',
        })}
      </Title>
      <div className="timeline">
        {data.map(({ title, description }, index) => (
          <div className={`timeline-container timeline-${index % 2 === 0 ? 'left' : 'right'}`}>
            <div className="timeline-content">
              <h2>{title}</h2>
              {description && <p>{description}</p>}
            </div>
          </div>
        ))}
      </div>
    </Root>
  );
};

export default Roadmap;
