import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const Title = styled.h2`
  text-align: center;
  font-size: 2rem;
  margin: 0 0 1rem 0;
  color: #e5007d;
  font-weight: bold;

  ${media.xl`
    font-size: 3rem;
  `}
`;

export default Title;
