import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LinksContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  a {
    color: #fff;

    + a {
      margin-left: 1rem;
    }
  }
`;

export const PoweredByLink = styled.a`
  font-size: 0.8rem;
  color: #fff;
  text-decoration: none;
  display: table;
  margin: 0 auto 1rem auto;

  img {
    width: 2rem;
  }

  ${media.lg`
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  `}
`;

const Root = styled.div`
  padding-top: 3rem;

  h2 {
    margin-top: 0;
  }

  position: relative;
`;

export default Root;
